.cases-list {
  @include reset-list;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.cases-list__item {
  flex-basis: 17.9%;
  margin-right: 2.6%;
  margin-bottom: 2.6%;

  &:last-of-type,
  &:nth-of-type(5n) {
    margin-right: 0;
  }
}

@media (max-width: $tablet-width) {
  .cases-list__item {
    flex-basis: 23%;
    margin-right: 2.6%;
    margin-bottom: 2.6%;
  
    &:nth-of-type(5n) {
      margin-right: 2.6%;
    }

    &:nth-of-type(4n),
    &:last-of-type {
      margin-right: 0;
    }
  }
}

@media (max-width: $mobile-width) {
  .cases-list__item {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 2.6%;

    &:nth-of-type(5n) {
      margin-right: 0;
    }
  }
}