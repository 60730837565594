.main-title {
  padding: 0;
  margin: 0;
  margin-bottom: 35px;
  font-size: 25px;
  line-height: 32px;
  font-weight: 500;

  &--decor {
    position: relative;
    padding-bottom: 3px;
    margin-bottom: 39px;
  }

  &--decor::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 21px;
    height: 3px;
    background-color: $decor-color--dark;
  }
}

@media (max-width: $mobile-width) {
  .main-title {
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 24px;
  }
}