.container {
  max-width: $desktop-width;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: $tablet-width) {
  .container {
    max-width: $tablet-continer-width;
  }
}

@media (max-width: $mobile-width) {
  .container {
    max-width: $mobile-continer-width;
    padding-right: 15px;
    padding-left: 15px;
  }
}
