.main-block {
  padding-top: 70px;
  padding-bottom: 64px;
  background-color: $bg-color--dark;
  background-image: url("../img/main-bg@1x.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
  .main-block {
    background-image: url("../img/main-bg@2x.jpg");
  }
}

.main-block__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.main-block__form-wrapper {
  max-width: 378px;
  min-width: 300px;
  width: 100%;
  padding: 23px 36px 30px 31px;
  background-color: $bg-color--light-opacity;
}

.main-block__text {
  max-width: 665px;
  padding-left: 5px;
  margin-top: 55px;
  margin-right: 70px;
  margin-bottom: 0;
  font-size: 29px;
  line-height: 35px;
  color: $text-color--lighten;
}

.main-block__form-text {
  margin: 0;
  margin-bottom: 21px;
  font-size: 17px;
  color: $text-color--lighten;
}

@media (max-width: $tablet-width) {
  .main-block__text {
    margin-right: 30px;
    font-size: 24px;
  }

  .main-block__form-text {
    font-size: 18px;
  }
}

@media (max-width: $mobile-width) {
  .main-block {
    padding-top: 30px;
    padding-bottom: 50px;
  }

  .main-block {
    background-image: url("../img/main-bg--mobile@1x.jpg");
  }
  
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    .main-block {
      background-image: url("../img/main-bg--mobile@2x.jpg");
    }
  }

  .main-block__wrapper {
    display: block;
  }

  .main-block__text {
    margin: 0;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }

  .main-block__form-wrapper {
    min-width: auto;
    margin: 0 auto;

  }

  .main-block__form-text {
    text-align: center;
  }
}