.main-form label {
  display: none;
}

.main-form input,
.main-form textarea {
  width: 100%;
  padding: 11px 13px;
  margin-bottom: 15px;
  border: none;
  font-size: 11px;
  border-radius: 0;
}

.main-form textarea {
  height: 72px;
  resize: none;
}

.main-form input[type='submit'],
.main-form button {
  @include main-btn;
  max-width: 144px;
  width: auto;
  display: block;
  margin: 0 auto;
  background-color: $decor-color--addition;
}

.main-form {
  position: relative;

  p {
    margin-bottom: 0;
  }

  div.wpcf7-response-output {
    height: 0;
  }

  .ajax-loader {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  span.wpcf7-not-valid-tip {
    margin-top: -13px;
    margin-bottom: 13px;
    font-size: 12px;
  }
}
