.info-list {
  @include reset-list;
}

.info-list__item {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 50px;
  margin-bottom: 40px;
}

.info-list__ico {
  position: absolute;
  left: 0;
  width: 28px;
  height: 28px;
  margin-right: 20px;
  fill: $decor-color--dark;

  &--timetable,
  &--mark {
    width: 31px;
    height: 32px;
  }
}

.info-list__link {
  @include links-effects;
  display: block;
  text-decoration: none;
  color: $text-color--main;
}

.info-list__title,
.info-list p {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 20px;
}
.info-list__title {
  font-weight: 600;
}

.info-list p {
  font-weight: 400;
}

@media (max-width: $tablet-width) {
  .info-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .info-list__item {
    flex-basis: 48%;
    margin-right: 2%;
    margin-bottom: 30px;

    &--fat {
      order: 1;
    }
  }
}

@media (max-width: $mobile-width) {
  .info-list {
    display: block;
  }

  .info-list__item {
    margin-right: 0;
  }
}