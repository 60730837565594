.review {
  color: $text-color--lighten;
}

.review img {
  display: block;
  max-width: 142px;
  width: 100%;
  height: 100%;
  margin: 0 auto 25px;
  object-fit: cover;
  object-position: center;
	font-family: 'object-fit: cover; object-position: center;';
  border-radius: 50%;
}

.review p,
.review h3 {
  font-size: 14px;
  line-height: 16px;
}

.review p {
  margin-top: 0;
  margin-bottom: 20px;
}

.review h3 {
  margin: 0;
}
