.header {
  padding-top: 12px;
  padding-bottom: 5px;
  background-color: $bg-color--clear;
}

.header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo {
  margin-top: 10px;
  margin-right: 40px;
}

.header__logo-ico {
  width: 187px;
  height: 69px;
}

.header__info-wrapper {
  width: 100%;
}

.header__toggle-btn {
  display: none;
}

@media (max-width: $tablet-width) {
  .header__logo {
    margin-right: 20px;
  }
}

@media (max-width: $mobile-width) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 30;

    &--nojs {
      position: static;
    }

    &--nojs .header__wrapper {
      display: block;
    }
  }

  .header__logo {
    margin-top: 0;
  }

  .header__logo-ico {
    width: 125px;
    height: 43px;
  }

  .header__toggle-btn {
    display: block;
    position: absolute;
    top: 7px;
    right: 0;
    display: block;
    width: 72px;
    height: 50px;
    border: none;
    background-color: transparent;
    transition: all 0.3s;
    cursor: pointer;
    order: 3;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.8;
    }

    &--closed {
      position: fixed;
      right: 0;
      z-index: 15;
    }

    &--nojs {
      display: none;
    }
  }

  .header__toggle {
    transition: all 0.3s;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 30px;
    height: 2px;
    background-color: $main-color--dark;
    transform: translate(-50%, -50%);

    &::after,
    &::before {
      content: "";
      position: absolute;
      right: 0;
      width: 30px;
      height: 2px;
      background-color: $main-color--dark;
    }

    &::after {
      top: 8px;
    }

    &::before {
      bottom: 8px;
    }

    &--closed {
      top: 50%;
      width: 34px;
      height: 2px;
      background-color: $main-color--dark;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &--closed::after {
      width: 0;
    }

    &--closed::before {
      top: 0;
      width: 34px;
      height: 2px;
      background-color: $main-color--dark;
      transform: rotate(90deg);
    }
  }

  .header__nav {
    margin-right: 0;
    display: none;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;

    &--nojs {
      display: block;
      flex-basis: 100%;
    }

    &--active {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      min-width: 100%;
      height: 100%;
      padding-top: 60px;
      padding-bottom: 60px;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      animation-name: showMenu;
      background-color: $bg-color--clear;
      z-index: 10;
    }

    &--active::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent;
    }

    &--close {
      animation-name: hiddenMenu;
    }
  }

  @keyframes showMenu {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes hiddenMenu {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
}
