.nav-links {
  @include reset-list;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 630px;
}

.nav-links__item {
  flex-basis: 14%;
  margin-right: 29%;
  margin-bottom: 2px;

  &:last-of-type,
  &:nth-of-type(3n) {
    margin-right: 0;
  }
}

.nav-links__link {
  transition: all 0.3s;
  display: block;
  padding: 2px 0;
  color: $text-color--lighten;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

@media (max-width: $tablet-width) {
  .nav-links__item {
    flex-basis: 48%;
    margin-right: 4%;
    margin-bottom: 6px;

    &:nth-of-type(3n) {
      margin-right: 4%;
    }
  
    &:last-of-type,
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
}

@media (max-width: $mobile-width) {
  .nav-links__item {
    flex-basis: 50%;
    margin-right: 0;

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }

  .nav-links__link {
    text-align: center;
  }
}