.footer {
  padding-top: 48px;
  padding-bottom: 24px;
  color: $text-color--lighten;
  background-color: $bg-color--darken;
}

.footer__wrapper {
  display: flex;
  justify-content: space-between;
}

.footer__left-wrapper {
  margin-right: 40px;
}

.footer__right-wrapper {
  flex-basis: 56.5%;
}

.footer__text {
  max-width: 240px;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 28px;
}

@media (max-width: $mobile-width) {
  .footer {
    padding-top: 30px;
  }

  .footer__wrapper {
    display: block;
  }

  .footer__left-wrapper {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .footer__text {
    max-width: 100%;
    text-align: center;
  }
}