.nav-list {
  @include reset-list;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;
}

.nav-list__item,
.nav-list li {
  margin-left: 20px;
  margin-bottom: 12px;
}

.nav-list li {
  li {
    @include reset-list;
    display: none;
  }

  &:hover li {
    display: block;
  }
}

.nav-list__link,
.nav-list a {
  @include links-effects;
  padding: 8px 5px;
  text-decoration: none;
  color: $text-color--main;
}

@media (max-width: $mobile-width) {
  .nav-list {
    flex-direction: column;
  }

  .nav-list__item,
  .nav-list li {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
  }

  .nav-list__link,
  .nav-list a {
    position: relative;
    display: block;
    width: 100%;
    font-size: 16px;
    padding: 17px;
    text-align: center;
  }
}
