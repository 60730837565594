.main-info {
  padding-top: 21px;
  padding-bottom: 1px;
  color: $text-color--lighten;
  background: linear-gradient(left, $bg-color--middle 50%, $bg-color--light 50%);
  background-color: $bg-color--middle;
}

.main-info__wrapper {
  display: flex;
  justify-content: flex-start;
}

.main-info__wrapper-column {
  flex-basis: 50%;
  display: flex;
  align-items: center;

  &--right {
    padding-left: 22px;
  }
}

.main-info__title {
  margin-top: 0;
  margin-bottom: 21px;
  margin-right: 43px;
  font-size: 13px;
}

@media (max-width: $tablet-width) {
  .main-info {
    padding-bottom: 10px;
  }

  .main-info__wrapper-column {
    display: block;
  }

  .main-info__title {
    margin-bottom: 10px;
  }
}

@media (max-width: $mobile-width) {
  .main-info {
    background-image: none;
  }

  .main-info__wrapper {
    display: block;
  }

  .main-info__wrapper-column {
    &--left {
      padding-bottom: 15px;
    }
  
    &--right {
      padding-left: 0;
    }
  }
}
