.reviews-list {
  @include reset-list;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.reviews-list__item {
  flex-basis: 30%;
  margin-right: 5%;
  margin-bottom: 35px;

  &:last-of-type,
  &:nth-of-type(3n) {
    margin-right: 0;
  }
}

@media (max-width: $tablet-width) {
  .reviews-list__item {
    flex-basis: 48%;
    margin-right: 4%;
    margin-bottom: 50px;
  
    &:nth-of-type(3n) {
      margin-right: 4%;
    }

    &:last-of-type,
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
}

@media (max-width: $mobile-width) {
  .reviews-list__item {
    flex-basis: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  
    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}