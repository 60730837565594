.metodics {
  padding-top: 34px;
  padding-bottom: 13px;
}

.metodics__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.metodics__content-wrapper {
  flex-basis: 60%;
  max-width: 592px;
  margin-right: 15px;
  margin-bottom: 7px;
  margin-left: 2px;

  &--nojs {
    max-width: 100%;
    margin-bottom: 34px;
  }
}

.metodics p {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 15px;
  font-weight: 400;
}

.metodics__slider {
  max-width: 500px;

  &--nojs {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.metodics__btn {
  margin-left: 3px;
}

@media (max-width: $tablet-width) {
  .metodics__inner {
    display: block;
  }

  .metodics__content-wrapper {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .metodics p {
    margin-bottom: 14px;
  }
}

@media (max-width: $mobile-width) {
  .metodics {
    padding-top: 24px;
  }

  .metodics__content-wrapper {
    margin-bottom: 20px;
  }
}
