.contacts-list {
  @include reset-list;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.contacts-list__item {
  max-width: 205px;
  margin-left: 36px;
  margin-bottom: 10px;
}

.contacts-list__text-row {
  display: block;
}

.contacts-list__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 2px;

  &--link {
    @include links-effects;
    padding: 8px 5px;
    color: $text-color--main;
    text-decoration: none;
  }
}

.contacts-list__ico {
  width: 26px;
  height: 26px;
  margin-right: 13px;
  fill: $decor-color--dark;

  &--mark {
    width: 26px;
    height: 30px;
  }

  &--timetable {
    width: 28px;
    height: 28px;
  }
}

.contacts-list p {
  margin: 0;
  font-size: 12px;
  line-height: 15px;
}

.contacts-list span {
  font-size: 17px;
}

@media (max-width: $tablet-width) {
  .contacts-list__item {
    max-width: 205px;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .contacts-list span {
    font-size: 14px;
  }

  .contacts-list__ico {
    margin-right: 10px;
  }
}

@media (max-width: $mobile-width) {
  .contacts-list {
    display: none;
  }
}
