html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: $main-font;
  color: $text-color--main;
  line-height: 23px;
  font-size: 14px;
  font-weight: 500;
}

img {
  max-width: 100%;
  height: auto;
}

@media (max-width: $mobile-width) {
  body {
    line-height: 20px;
    font-size: 14px;
  }
}
