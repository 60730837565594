.contacts {
  overflow: hidden;
}

.contacts__info-inner {
  position: relative;
  max-width: 359px;
  width: 100%;
  padding-top: 37px;
  padding-bottom: 4px;
  padding-right: 50px;
  margin-right: auto;
}

.contacts__map-wrapper {
  position: absolute;
  top: 50%;
  left: 100%;
  max-width: 804px;
  width: 100%;
  transform: translateY(-50%);
}

.contacts__map {
  z-index: 5;
  border: none;
}

.contacts__map,
.contacts__map-img {
  max-width: 804px;
}

.contacts__map-img {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: auto;
  object-fit: cover;
  object-position: center;
	font-family: 'object-fit: cover; object-position: center;';
  transform: translateY(-50%);
}

@media (max-width: $tablet-width) {
  .contacts {
    overflow: inherit;
  }

  .contacts__info-inner {
    max-width: 100%;
    padding-right: 0;
    padding-bottom: 0;
  }

  .contacts__map-wrapper {
    position: relative;
    left: 0;
    transform: none;
    display: block;
    max-width: 100%;
    height: 526px;
    margin-right: auto;
    margin-left: auto;
  }

  .contacts__map,
  .contacts__map-img {
    max-width: 100%;
    width: 100%;
    height: 526px;
  }

  .contacts__map {
    line-height: 0;
  }

  .contacts__map-img {
    top: 0;
    transform: none;
  }
}

@media (max-width: $mobile-width) {
}
