.reviews {
  padding-top: 30px;
  padding-bottom: 36px;
  background-color: $bg-color--middle;
}

.reviews__title {
  text-align: center;
  color: $text-color--lighten;
}

@media (max-width: $tablet-width) {
  .reviews {
    padding-bottom: 10px;
  }
}

@media (max-width: $mobile-width) {
  .reviews {
    text-align: center;
  }
}