.case {
  transition: all 0.3s;
  max-width: 200px;
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  background-color: $bg-color--darken;
  text-align: center;
  z-index: 10;

  &:hover {
    box-shadow: 0 0 5px $decor-color--dark;
  }

  &:active {
    opacity: 0.8;
  }
}

@media (pointer: coarse) {
  .case {
    cursor: pointer;
  }
}

.case a {
  display: block;
  width: 100%;
  padding: 38px 28px 33px;
  text-decoration: none;
  color: $text-color--lighten;
}

.case h3 {
  margin-top: 0;
  margin-bottom: 47px;
  font-weight: 500;
  text-transform: uppercase;
}

.case p {
  margin: 0;
  line-height: 20px;
}

.case img {
  max-width: 200px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
	font-family: 'object-fit: cover; object-position: center;';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media (max-width: $mobile-width) {
  .case {
    max-width: 400px;
  }

  .case img {
    max-width: 100%;
  }
}