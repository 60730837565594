.metodics-slider {
  @include reset-list;
  margin-bottom: 30px;
  overflow: hidden;

  .slick-arrow {
    width: 17px;
    height: 17px;
    z-index: 10;
    background-repeat: no-repeat;
    background-position: cover;
  }

  .slick-arrow:focus {
    box-shadow: 0 0 2px 2px $decor-color--dark;
  }

  &--nojs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 60px;
  }

  .slick-arrow::before {
    display: none;
  }

  .slick-next {
    right: 2px;
    background-image: url("../img/arrow-next.svg");
  }

  .slick-prev {
    left: 2px;
    background-image: url("../img/arrow-prev.svg");
  }
}

.metodics-slider__img {
  display: block;
  max-width: 433px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  object-fit: cover;
  object-position: center;
  font-family: "object-fit: cover; object-position: center;";
}

@media (max-width: $tablet-width) {
  .metodics-slider {
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: $mobile-width) {
  .metodics-slider {
    margin-bottom: 15px;
  }
}