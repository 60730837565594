.social-list {
  @include reset-list;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  max-width: 360px;
}

.social-list__item {
  margin-right: 18px;
  margin-bottom: 5px;
  max-width: 50px;
  width: 100%;
  max-height: 50px;
}

.social-list__link {
  transition: all 0.3s;
  display: block;
  padding: 2px;
  line-height: 0;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

.social-list__ico {
  width: 48px;
  height: 48px;
}

@media (max-width: $mobile-width) {
  .social-list {
    justify-content: center;
    max-width: 100%;
  }

  .social-list__item {
    margin-right: 9px;
    margin-left: 9px;
  }
}