@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin main-btn {
  transition: all 0.3s;
  display: inline-block;
  min-width: 144px;
  padding: 10px 23px;
  border: none;
  border-radius: 50px;
  font-size: 17px;
  font-weight: 400;
  color: $text-color--lighten;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  background-color: $decor-color--dark;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
}

@mixin links-effects {
  transition: all 0.3s;

  &:hover {
    color: $decor-color;
  }

  &:active {
    opacity: 0.6;
  }
}
