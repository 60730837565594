.main-list {
  @include reset-list;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.main-list__item {
  flex-basis: 28%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 22px;
  font-size: 12px;
  font-weight: 400;
}

.main-list__ico {
  fill: $decor-color--lighten;
  margin-right: 7px;
}

@media (max-width: $tablet-width) {
  .main-list__item {
    flex-basis: 40%;
  }
}

@media (max-width: $mobile-width) {
  .main-list__item {
    flex-basis: 45%;
    margin-right: 10%;

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
}